<!--警报管理按钮-->
<template>
  <div id="colorPickerBoard" :title="$t('sidebar.e2')">
    <a-row type="flex" justify="start" align="middle">
        <a-col :span="6">
            <a-input :value="color"></a-input>
        </a-col>
        <a-col :span="6">
            <a-tag class="tag" :color="color"></a-tag>
        </a-col>
    </a-row>
    <a-row> 
        <a-col :span="12">
            <vueColorPickerBoard  class="colorBoard" :width="800" :height="600" :defaultColor="'#00AAFF'" @onSelection="colorSelection"></vueColorPickerBoard>  
        </a-col>
    </a-row> 
  </div>
</template>

<script>
import VueColorPickerBoard from 'vue-color-picker-board';
export default {
  name:'colorPickerBoard',
  data() {
    return {
      color: '#ff0000',
    };
  }, 
  methods: {    
    colorSelection(e){
      console.log("colorSelection:",e);
      this.color=e+'';
      this.$emit('selectColor',this.color);
    },
  },
  components: {
    "vueColorPickerBoard":VueColorPickerBoard,
  }, 
};
</script>

<style scoped>
#colorPickerBoard {
  width: 100%;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  padding: 10px 0;
}
.colorBoard{
    margin-top: 20px;
}
.tag {
  margin-left: 10px;
  width: 20px;
  height: 20px;
}
</style>