<!--设备模块--实际数据--趋势图-->
<template>
  <div v-loading="loading"
    id="dataCompareTrendChart"
    :element-loading-text="$t('dataCompareTrendList.a1')"
    ref="dataCompareTrendChart"
    
  >
    <div class="main">   
      <!-- 曲线图 -->
        <div id="myChart" v-if="isShow" :style="{width: '100%', height: '400px'}"></div>
    </div>
    <div class="footer">
      <a-button
        type="primary"        
        @click="reload"
        >{{$t('dataCompareTrendList.a3')}}</a-button
      >
    </div>    
  </div>
</template>

<script>

import headerTitle from "../../../components/Title/headerTitle";
import {getTrendData} from "../../../api/device";

export default {  
  props:{
    signalPoint:{},
    dates:Array,
    div:"",
  },
  data() {
    return {
      loading:false,
      isShow:false,
      trend:{},
      dataList: [],
    };
  },
  created(){
    // this.getData();
  },
  mounted() {   
    this.getData();     
  },
   watch: {     
    isShow (val, oldVal) {
      if (val) {
          this.sleep(this.time).then(()=>{         
            this.drawLine();            
          })       
      }
    }
  },
  computed: {    
  },
  methods: {    
    sleep(ms) {
      return new Promise(resolve => 
        setTimeout(resolve, ms)
       )
    },
    // 页面后退
    goBack() {
      this.$router.go(-1);
    },
    reload(){//重新加载数据
      this.getData();  
    },         
    getData(){
      this.loading=true;
      let dateString = this.dates.join(','); 
      let tagPoints="";
      let tagPoint = "";
      if(this.signalPoint!=null){
        console.log("getTrendData signalPoint",this.signalPoint);
          tagPoint = this.signalPoint.signalNo+"@@"+this.signalPoint.signalKind+"@@"+this.signalPoint.trendType;
          if(tagPoints=="")
            tagPoints = tagPoint;
          else
            tagPoints = tagPoints+","+tagPoint;        
      }
      
      let par = {
        tagPoints: tagPoints,
        dates: dateString,
        div:this.div,
      };

      console.log("getTrendData par",par);
      getTrendData(par).then((res) => {
            let { data } = res;
            this.dataList = data;
            this.isShow=true;
            this.loading=false;
            console.log("getTrendData：",this.dataList);              
          })
          .catch((err) => {
            console.log(err);
            this.loading=false;
          });
    } ,  
    chartResize(){
        try{
            let o = document.getElementById("myChart");
            if(o!=null){
                this.$echarts.init(document.getElementById("myChart")).resize();
            }
        }catch(e){
            console.log(e);
        } 
    },    
    getNumber(value){
      let statelist = this.signalPoint.multiStateList;                
      if(statelist!=null&&statelist.length>0){
        for(let i=0;i<statelist.length;i++){                                  
            if(value===statelist[i].text){
              return statelist[i].no;
            }                
        }            
      }        
      return value;     
    },
    drawLine() {
      // 基于准备好的dom，初始化echarts实例      
      console.log("drawLine dataList",this.dataList);   

      let myChart = this.$echarts.init(document.getElementById("myChart"));      

      let legendData=[];
      let categories = [];      
      if(this.dates!=null &&this.dates.length>0){
        for(let i=0;i<this.dates.length;i++){
          legendData.push(this.dates[i]);          
        }
      }
      
      let valueData=[];     
      valueData[0]=[]; 
      valueData[1]=[];
      valueData[2]=[];
      valueData[3]=[];
      valueData[4]=[];
      if(this.dataList!=null&&this.dataList.length>0){       
        for(let i=0;i<this.dataList.length;i++){
          //取x轴坐标
          categories.push(this.dataList[i].time);          
          //取y轴数据值
          if(this.dataList[i].value1!=null){//如果存在value1
            if(!valueData[0]){
              valueData[0]=[];
            }
            valueData[0].push(this.getNumber(this.dataList[i].value1));
          }
          if(this.dataList[i].value2!=null){//如果存在value2
            if(!valueData[1]){
              valueData[1]=[];
            }
            valueData[1].push(this.getNumber(this.dataList[i].value2));
          }
          if(this.dataList[i].value3!=null){//如果存在value3
            if(!valueData[2]){
              valueData[2]=[];
            }
            valueData[2].push(this.getNumber(this.dataList[i].value3));
          }
          if(this.dataList[i].value4!=null){//如果存在value4
            if(!valueData[3]){
              valueData[3]=[];
            }
            valueData[3].push(this.getNumber(this.dataList[i].value4));
          }
          if(this.dataList[i].value5!=null){//如果存在value5
            if(!valueData[4]){
              valueData[4]=[];
            }
            valueData[4].push(this.getNumber(this.dataList[i].value5));
          }          
        }
      }      
      console.log("drawLine valueData:",valueData);
      let series=[];
      let serie = null;
      if(valueData.length>0){
        for(let i=0;i<valueData.length;i++){
          serie = {
              name: this.dates[i],
              type: 'line',
              // stack: '总量',
              data: valueData[i]
          };
          series.push(serie);
        }
      }
      console.log("drawLine series:",series);

      console.log("drawLine signalPoint",this.signalPoint);
      let statelist = this.signalPoint.multiStateList;
            
      var yAxis={};       
      if(this.signalPoint.signalKind=="analog"){
        yAxis={
              type: 'value',  
              formatter: '{value}'+ this.signalPoint.unit,       
          };
      }
      else{
        yAxis={
              type: 'value',
              min: 0,
              max: 4,
              axisLabel:{
                formatter:function(value){  
                  var texts=[];                  
                  if(statelist!=null&&statelist.length>0){
                    for(let i=0;i<statelist.length;i++){                                  
                        if(value+""===statelist[i].no){
                          texts.push(statelist[i].text);
                        }                
                    }            
                  }   
                  
                return texts;  
                }
              },             
        };
      }

      let option = {
          title: {
              text: '数据比较趋势图'
          },
          tooltip: {
              trigger: 'axis'
          },
          legend: {
              data: legendData
          },
          grid: {
              left: '3%',
              right: '4%',
              bottom: '3%',
              containLabel: true
          },
          toolbox: {
              feature: {
                  saveAsImage: {}
              }
          },
          xAxis: {
              type: 'category',
              boundaryGap: false,
              data: categories
          },
          yAxis: yAxis,
          series: series,
      };      
      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);   
    },
  },
  components: {
    "my-headertitle": headerTitle,
  },
};
</script>

<style scoped>
div /deep/.ant-modal-header {
  background-color: #7682ce;
}
#dataCompareTrendChart {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  overflow: auto;
}
.header {
  height: 30px;
  padding: 10px 20px 0px 20px;
}
.main {
  width: 100%;
  /* height: calc(100% - 113px); */
  padding: 10px 20px;
  overflow: auto;
}
.footer {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0px 20px;
  overflow: hidden;
  border-top: 1px solid rgba(12, 12, 12, 0.1);
}
.footer button {
  margin-left: 10px;
  width: 120px;
}
</style>