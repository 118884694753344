<!--设备模块--数据比较趋势图表-->
<template>
  <div id="dataCompareTrend" ref="dataCompareTrend">
    <div class="aside">
      <a-spin :spinning="spinning" style="width: 100%; height: 100%">
        <my-navder
          :list="list"
          @select="getMsgFormSon"
          :style="style"
        ></my-navder>
      </a-spin>      
    </div>
    <div class="right">
      <a-spin :spinning="loading" class="main-spinning" v-if="id">
        <div class="main">          
          <my-headertitle>{{$t('dataCompareTrend.a1')}}</my-headertitle>
          <div class="info">
            <div>
              <span>{{$t('dataCompareTrend.a2')}}</span>
              <span>{{ nodeName }}</span>        
            </div>
            <div>
              <span>{{$t('dataCompareTrend.a3')}}</span>
              <span>{{ nodeId }}</span>
            </div>
          </div>
          <!-- <my-tabletitle>信号点选择</my-tabletitle> -->
          <a-table 
            :columns="columns" 
            :data-source="signalPoints"   
            :pagination=false                      
            >
              <span slot="items" slot-scope="record">                
                  <a-select :value="record.signalPoint" style="width: 180px" @change="handleSignalPointChange">
                    <a-select-option v-for="item in infoList" :key="item.key">
                      {{ item.signalPoint }}
                    </a-select-option>
                  </a-select>             
              </span>
          </a-table>     
          <a-button class="editable-add-btn" @click="handleAdd">
             {{$t('dataCompareTrend.a4')}}
          </a-button>      
          <!-- <colorPicker v-model="color" v-on:change="headleChangeColor"></colorPicker>            -->
          <a-table    
            class="date-table"         
            :columns="chooseDateColumns" 
            :data-source="chooseDateList" 
            :pagination=false               
            :customRow="click"         
            >
              <span  slot="date" slot-scope="record">
                <a-date-picker :value ="record.date" @change="handleDateChange" />
                <br />
              </span >
              <span  slot="chart" >                
                  <a-select
                    class="select"                    
                    :value="chartType"                    
                    disabled = 'disabled'                   
                    @change="handleChartTypeChange"                   
                  >
                    <a-select-option value="ON/OFF">
                      ON/OFF
                    </a-select-option>
                    <a-select-option value="brokenLine">
                      {{$t('dataCompareTrend.a27')}}
                    </a-select-option>
                    <a-select-option value="multiState">
                      {{$t('dataCompareTrend.a28')}}
                    </a-select-option>
                  </a-select>   
                  <!--图表已自动分配颜色，不再手动设置-->
                  <!-- <a-tag class="tag" :color="record.color" @click="onColorClick"></a-tag>   -->
                  <!-- <colorPicker v-model="color" v-on:change="headleChangeColor"></colorPicker>  -->
                  <a-modal
                    :title="titleColorBoard"
                    :width="850"
                    :visible="visibleColorBoard"
                    @ok="handleOkColorBoard"
                    @cancel="handleCancelColorBoard"
                  >
                  <my-colorPickerBoard @selectColor="onSelectColor"></my-colorPickerBoard>
                </a-modal>     
              </span >
              <span  slot="operation" slot-scope="text, record">
                <a-popconfirm
                  v-if="chooseDateList.length"
                  :title="$t('dataCompareTrend.a5')"
                  @confirm="() => onDelete(record.key)"
                >
                  <a href="javascript:;">{{$t('dataCompareTrend.a6')}}</a>
                </a-popconfirm>
              </span >              
          </a-table>    
          <!--图表已自适应设置Y轴-->
          <!-- <a-table 
            class="axis-table"
            :columns="formatColumns" 
            :data-source="formatList"  
            :pagination=false        
            >
              <template slot="unit" slot-scope="text, record">
                  <a-select class="select" v-model="record.unit" default-value="$t('dataCompareTrend.a7')"  @change="handleChange">
                    <a-select-option value="$t('dataCompareTrend.a7')">
                      {{$t('dataCompareTrend.a7')}}
                    </a-select-option>
                    <a-select-option value="℃">
                      ℃
                    </a-select-option>
                    <a-select-option value="$t('dataCompareTrend.a8')">
                      {{$t('dataCompareTrend.a8')}}
                    </a-select-option>
                  </a-select>         
              </template>
              <template slot="max" slot-scope="text, record">
                <a-input v-model="record.max" />
              </template>   
              <template slot="min" slot-scope="text, record">
                <a-input v-model="record.min" />
              </template>  
              <template slot="count" slot-scope="text, record">
                 <a-select class="select" v-model="record.count" @change="handleChange">
                    <a-select-option v-for="item in countList" :key="item.key">
                      {{ item.value }}
                    </a-select-option>
                  </a-select>         
              </template>          
          </a-table>    -->
        </div>
      </a-spin>
      <div class="main-show" v-else>
        <a-icon type="select" style="font-size: 60px; margin-bottom: 20px" />
        <span>{{$t('dataCompareTrend.a9')}}</span>
      </div>
      <div class="footer" v-if="id">
        <a-button type="primary" @click="showList">{{$t('dataCompareTrend.a10')}}</a-button>
        <a-button type="primary" @click="showChart">{{$t('dataCompareTrend.a11')}}</a-button>
      </div>
      <a-modal
        :title="title"
        :width="1000"
        :visible="visible"
        :footer="null"
        @cancel="handleCancel"
        :destroyOnClose="destroyOnClose"
      >
      <my-dataCompareTrendChart :signalPoint="selectSignalPoint" :dates="dates" :div="div"></my-dataCompareTrendChart>
    </a-modal>
    <a-modal
        :title="title"
        :width="1000"
        :visible="visibleList"
        :footer="null"
        @cancel="handleCancel"
      >
      <my-dataCompareTrendList :signalPoint="selectSignalPoint" :dates="dates" :div="div"></my-dataCompareTrendList>
    </a-modal>
    </div>        
  </div>
</template>

<script>
import tabletitle from "../../../components/Title/tabletitle";
import navdar from "../../../components/Navdar/vertical";
import headerTitle from "../../../components/Title/headerTitle";
import { lightTimetableListKT,getTrendDeviceInfo,getDataCompareTrendPar } from "../../../api/device";
import select from "../../../components/Select/select";
import dataCompareTrendChart from "./dataCompareTrendChart";
import dataCompareTrendList from "./dataCompareTrendList";
import colorPickerBoard from "../colorPickerBoard";
import moment from 'moment';

export default {
  data() {
    return {
      selectColor:'#ff0000',
      title:this.$t('dataCompareTrend.a1'),      
      dates:["","","","","",""],
      disabled:true,
      list: [],
      arr: [],
      MAX_NUM: 1,
      timer: null,
      carriedOut: true,
      pos: 0,
      spinning: false,
      id: undefined,//包含类型和时间表ID
      loading: false,
      schType: 11,
      nodeId: undefined,//时间表ID
      nodeName: undefined,//时间表名称
      nodeParentName: undefined,//时间表父节点名称
      visible: false,
      destroyOnClose:true,
      titleColorBoard:"颜色选择",
      visibleColorBoard:false,
      visibleList:false,
      confirmLoading: false,
      copyKey: undefined,
      state: false,
      isActive: undefined,
      loadingOK: false,
      div:"1日",//默认为1日
      signalPoints:[],
      selectSignalPoint:{},
      infoList:[],
      countList:[],
      chooseDateList:[],
      chooseDateInfo:{},
      formatList:[
        {
          index:1,
          name: this.$t('dataCompareTrend.a12'),
          unit: this.$t('dataCompareTrend.a7'),
          max:1,
          min:1,
          count:10,
        },
        {
          index:2,
          name: this.$t('dataCompareTrend.a13'),
          unit: this.$t('dataCompareTrend.a7'),
          max:1,
          min:1,
          count:10,
        }
      ],
      formatColumns:[
        {
          title: '',
          dataIndex: "name",
          // width: 120,
          ellipsis: true,
        },  
        {
          title: this.$t('dataCompareTrend.a14'),
          scopedSlots: { customRender: 'unit' },
          // width: 100,
          ellipsis: true,
        },
        {
          title: this.$t('dataCompareTrend.a15'),
          // width: 120,
          scopedSlots: { customRender: 'max' },
          ellipsis: true,
        },
        {
          title: this.$t('dataCompareTrend.a16'),
          // width: 80,
          scopedSlots: { customRender: 'min' },
          ellipsis: true,
        }, 
        {
          title: this.$t('dataCompareTrend.a17'),
          // width: 100,
          scopedSlots: { customRender: 'count' },
          dataIndex: "count",
          ellipsis: true,
        },
      ],
      chooseDateColumns:[
        {
          title: this.$t('dataCompareTrend.a18'),
          scopedSlots: { customRender: 'date' },
          width: 120,
          ellipsis: true,
        },  
        {
          title: this.$t('dataCompareTrend.a19'),
          dataIndex: "day",
          width: 80,
          ellipsis: true,
        },
        {
          title: this.$t('dataCompareTrend.a20'),
          scopedSlots: { customRender: 'chart' },
          dataIndex: "chart",
          width: 120,
          ellipsis: true,
        },
        {
          title: this.$t('dataCompareTrend.a21'),
          width: 80,
          scopedSlots: { customRender: 'operation' },
          ellipsis: true,
        },       
      ],
      columns : [
        {
          title: this.$t('dataCompareTrend.a22'),
          width: 140,
          scopedSlots: { customRender: 'items' },
        },  
        {
          title: this.$t('dataCompareTrend.a23'),
          dataIndex: "dvName",
          width: 100,
          ellipsis: true,
        },
        {
          title: this.$t('dataCompareTrend.a24'),
          dataIndex: "signalName",
          width: 140,
          ellipsis: true,
        },
        {
          title: this.$t('dataCompareTrend.a25'),
          dataIndex: "signalTypeDesc",
          width: 50,
          ellipsis: true,
        },
        {
          title: "TagPoint",
          dataIndex: "tagPoint",
          width: 80,
          ellipsis: true,
        },
      ],
    };
  },
  // 获取侧边栏数据同时执行虚拟化列表的方法
  async mounted() {
    this.spinning = true;
    // 进入页面时调用获取页面左侧列表方法
    this.navderList();    
    this.spinning = false;
    this.countList.length=0;
    for(let i=1;i<=20;i++){
      this.countList.push({key:i,value:i})
    }
  },
  computed: {
    // 侧边栏的高度计算和padding-top计算
    style: function () {
      if (this.pos !== 0) {
        return `height:${48 * this.arr.length}px;padding-top:${
          this.pos * 48 - 96
        }px`;
      } else {
        return `height:${48 * this.arr.length}px;padding-top:0px`;
      }
    },
    chartType:function(){
      let chartType = "ON/OFF";
      switch(this.selectSignalPoint.signalKind){
        case "binary":
          chartType = "ON/OFF";
          break;
        case "multi":
          chartType = "multiState";
          break;
        default:
          chartType = "brokenLine";
          break;      
      }
      return chartType;
    }
  },
  //缓存页面勾子函数
  activated(){
    this.getdata();
  },
  watch: {
    // 监听侧边栏选择的id，并加载页面数据
    id: function (newQuestion, oldQuestion) {      
      this.getdata();
      this.getDataCompareTrendPar();
    },
    p1: function () {
      this.selectList = this.arr.slice(this.p1, this.p2);
    },
    '$route'(to,from){
      this.spinning = true;
      // 进入页面时调用获取页面左侧列表方法
      this.navderList();    
      this.spinning = false;
    },
  },
  methods: {    
    onSelectColor(msg){
      console.log("selectColor:",msg);
      this.selectColor = msg;
    },
    headleChangeColor(e){
      console.log("headleChangeColor:",e);
      // this.color='#ff00ff';
    },
    click(record, index){
        return {
            on: {
               click: () => {
                    // console.log(record,index)
                    this.chooseDateInfo = record;
                    // console.log("row click1:",this.chooseDateList[index]);
                    console.log("row click2:",record);
               }
            }
        }
    },
    onDelete(key) {
      const dataSource = [...this.chooseDateList];
      console.log("choose chooseDateList:",this.chooseDateList);
      console.log("choose key:",key);
      this.chooseDateList = dataSource.filter(item => item.key != key);
    },
    handleAdd() {
      if(this.chooseDateList!=null&&this.chooseDateList.length<6){//限制最大6天
        let myDate = moment();
        const newData = {
          key:this.chooseDateList.length,
          day: myDate.day(),
          chart: "brokenLine",
          date: myDate,
          color: "#00AAFF",
          dateString: myDate.format('YYYY-MM-DD')
        };
        console.log('handleAdd:',newData);
        this.chooseDateList.push(newData);
      }
    },
    //日期修改响应
    handleDateChange(date, dateString) {
      console.log(date, dateString);
      if(date!=null&&date.day()!=null){
        this.chooseDateInfo.date = date;
        this.chooseDateInfo.day = date.day();
      }
      if(dateString!=null)
        this.chooseDateInfo.dateString = dateString;
      console.log('handleDateChange:',this.chooseDateInfo);
    },
    handleChartTypeChange(value){
      this.chooseDateInfo.chart = value;
      console.log('ChartTypeChange：',value,this.chooseDateInfo);
    },
    handleSignalPointChange(e){
      this.signalPoints.length=0;
      this.selectSignalPoint = this.infoList[e-1];
      this.signalPoints.push(this.selectSignalPoint);      
      console.log('selectSignalPoint changed：',e, this.selectSignalPoint);
    },
    //下拉选择响应
    handleChange(e){      
      console.log('select changed', e);
    },
    // 获取页面左侧列表方法
    navderList() {
      this.spinning = true;
      let name = this.$route.name;
      if(name=="VRFAC_FAC_DATA_HIKAKU_TLEND" || name=="LIGHTING_FAC_DATA_HIKAKU_TLEND"||name=="ENVIRONMENT_FAC_DATA_HIKAKU_TLEND"||name=="FACILITIES_FAC_DATA_HIKAKU_TLEND")
        this.schType=11; 
      if(name=="VRFAC_FAC_TIME_TLEND" || name=="LIGHTING_FAC_TIME_TLEND"||name=="ENVIRONMENT_FAC_TIME_TLEND"||name=="FACILITIES_FAC_TIME_TLEND")
        this.schType=12; 

      //CHW ADD 能源模块添加趋势数据
      console.log('navderList>>'+name);
      if(name=="ENE_DATA_HIKAKU_TREND")
        this.schType=11; 
      if(name=="ENE_TIME_TREND")
        this.schType=12; 
      //CHW ADD 能源模块添加趋势数据 

      let par = {
        siteId: this.$route.query.id,
        schType: this.schType,
      };
      lightTimetableListKT(par)
        .then((res) => {
          this.spinning = false;
          let { data } = res;
          data.key = "sub1";
          this.list.length=0;
          this.list.push(data);          
        })
        .catch((err) => {
          this.spinning = false;
          console.log(err);
        });
    },
  
    //查找参数数据
    getDataCompareTrendPar(){ 
      this.loading = true; 
      let par = {
        id: this.nodeId,
      };     
      getDataCompareTrendPar(par)
        .then((res) => {
          this.loading = false;
          let { data } = res;          
          console.log("getDataCompareTrendPar data：",data);
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },

    // 加载页面数据的方法    
    getdata() {
      this.loading = true; 
      let par = {
        siteId:this.$route.query.id,
        trendGroupId: this.nodeId,
        trendGroupName: this.nodeName
      };     
      getTrendDeviceInfo(par)
        .then((res) => {
          this.loading = false;
          let { data } = res;
          this.infoList.length=0;
          this.signalPoints.length=0;
          if(data.binaryInfoList!=null && data.binaryInfoList.length>0){
              for(let i=0;i<data.binaryInfoList.length;i++)
                this.infoList.push(data.binaryInfoList[i]);   
          }         
          if(data.analogInfoList!=null && data.analogInfoList.length>0)
            for(let i=0;i<data.analogInfoList.length;i++)
                this.infoList.push(data.analogInfoList[i]);
          if(data.multiStateInfoList!=null && data.multiStateInfoList.length>0)
            for(let i=0;i<data.multiStateInfoList.length;i++)
                this.infoList.push(data.multiStateInfoList[i]);
          if(data.accumulatorInfoList!=null && data.accumulatorInfoList.length>0)
            for(let i=0;i<data.accumulatorInfoList.length;i++)
                this.infoList.push(data.accumulatorInfoList[i]);
          if(data.calculateInfoList!=null && data.calculateInfoList.length>0)
            for(let i=0;i<data.calculateInfoList.length;i++)
                this.infoList.push(data.calculateInfoList[i]);     
          for (let i = 0; i < this.infoList.length; i++) {
            this.infoList[i].key = i + 1;
            this.infoList[i].signalPoint = this.infoList[i].dvName+"--"+this.infoList[i].signalName;
          }
          //初始化
          if(this.infoList.length>0){
            this.signalPoints.push(this.infoList[0]);
            this.selectSignalPoint = this.infoList[0];
            console.log("getTrendDeviceInfo--加载有效数据：",this.infoList);
          }
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },     
    // 点击侧边栏获取页面详细数据加载id
    getMsgFormSon(data) {
      this.id = data;
      let number = this.id.split(",");
      this.nodeId = number[1];
      let schedule = this.list[0].children[0].children;
      this.nodeParentName = this.list[0].children[0].title;
      for(var i=0;i<schedule.length;i++){
          if(this.id == schedule[i].key){
            this.nodeName = schedule[i].title;
            break;
          }
      }
      console.log("timeTable--获取选择时间表名称：",this.nodeParentName);      
    },
    // 虚拟化侧边栏列表
    virtual() {
      if (this.carriedOut) {
        let height = this.$refs.container.clientHeight;
        this.MAX_NUM = Math.ceil(height / 48);
        let scrollTop = this.$refs.container.scrollTop;
        this.pos = Math.round(scrollTop / 48);
        console.log("TimeTable-virtual",this.list);
        this.list[0].children[0].children = this.arr.slice(
          this.pos,
          this.pos + this.MAX_NUM
        );
        this.carriedOut = false;
        this.timer = setTimeout(() => {
          this.carriedOut = true;
          clearTimeout(this.timer);
        }, 50);
      }
    },
    // 图表显示
    showChart() {
      console.log("点击图表显示：",this.infoList);
      if(this.infoList==null||this.infoList.length==0){
        this.$message.error(this.$t('timeSetupTrend.a26'));
        return;
      } 
      if(this.chooseDateList==null||this.chooseDateList.length==0){
        this.$message.info(this.$t('dataCompareTrend.a26'));
        return;
      }
      //获取日期
      for(let i=0;i<this.chooseDateList.length;i++){
        this.dates[i] =  this.chooseDateList[i].dateString;
      }
      this.visible=true;
    },
    handleCancel(){
      this.visible = false;
      this.visibleList=false;
    },
    onColorClick(){
      this.visibleColorBoard=true;
    },
    handleOkColorBoard(){
      this.chooseDateInfo.color=this.selectColor;
      this.visibleColorBoard=false;
    },
    handleCancelColorBoard(){
      this.visibleColorBoard=false;
    },
    // 列表显示
    showList() {
      console.log("点击列表显示：",this.chooseDateList);
      if(this.infoList==null||this.infoList.length==0){
        this.$message.error(this.$t('timeSetupTrend.a26'));
        return;
      } 
      if(this.chooseDateList.length==0){
        this.$message.info(this.$t('dataCompareTrend.a26'));
        return;
      }

      //获取日期
      for(let i=0;i<this.chooseDateList.length;i++){
        this.dates[i] =  this.chooseDateList[i].dateString;
      }
      this.visibleList=true;
      // this.$router.push({
      //   path: "/homepage/air-condition/dataCompareTrendList",
      //   query: { id: this.$route.query.id, nodeId: this.nodeId,nodeName:this.nodeName,schType:this.schType,
      //   signalPoint:this.selectSignalPoint,dates:this.dates,div:this.div},
      // });
    }, 
       
  },
  components: {
    "my-tabletitle": tabletitle,
    "my-navder": navdar,
    "my-headertitle": headerTitle,
    "my-select": select,
    "my-dataCompareTrendChart":dataCompareTrendChart,
    "my-dataCompareTrendList":dataCompareTrendList,
    "my-colorPickerBoard":colorPickerBoard,
  },
};
</script>
<style scoped>
#dataCompareTrend {
  height: 100%;
  display: flex;
}
div /deep/.ant-modal-header {
  background-color: #7682ce;
}
.right {
  width: calc(100% - 250px);
  display: flex;
  flex-direction: column;
}
.aside {
  width: 250px;
  height: 100%;
  overflow: auto;
}
.main {
  width: 100%;
  height: 100%;
  overflow: auto;
}
.main-spinning {
  width: 100%;
  height: 100%;
  padding: 0px 20px 20px 20px;
  overflow: auto;
}
.main-show {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  color: rgba(12, 12, 12, 0.3);
  letter-spacing: 5px;
  white-space: nowrap;
}
.select{
  width: 120px;
}
.info {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  font-size: 14px;
  margin-bottom: 20px;
  white-space: nowrap;
  overflow: hidden;
}
.footer {
  height: 55px;
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid rgba(12, 12, 12, 0.1);
  padding: 10px 10px;
}
input{
  width: 100px;
}
button {
  /* width: 100px; */
  margin: 0px 10px;
}
.date-table {
  margin-top: 10px;
  margin-bottom: 15px;
}
.axis-table {
  margin-top: 10px;
  margin-bottom: 15px;
}
.editable-add-btn {
  margin-top: 15px;
  margin-bottom: 5px;
}
.tag{
  width:20px;
  height: 20px;
  margin-left: 10px;
  margin-top: 10px;
}
</style>